import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Link } from "gatsby"
export default function ThankYou() {
  return (
    <Layout>
      <Seo title="Cảm ơn quý khách" />
      <div className="big-title mb-5">
        <div className="container text-center">
          <h1> Cảm ơn quý khách đã quan tâm tới sản phẩm của chúng tôi</h1>
        </div>
      </div>
      <div className="container pt-5 pb-5 text-center error-page">
        <h3>Chúng tôi đã nhận được yêu cầu của quý khách</h3>
        <p>Chúng tôi sẽ liên hệ với quý khách trong thời gian sớm nhất!</p>
        <div className="error-actions">
          <Link to="/">Về trang chủ</Link>
        </div>
      </div>
    </Layout>
  )
}
